// Fonts ===============================================
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
// =====================================================

// Generale Style ======================================

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// fonts
$font-1: "Rubik", sans-serif;
$font-2: "Poppins", sans-serif;
// color
$main-color: #e91e63;
$color-opacity: #ff52521a;
$color-pink: #e91e63;
$color-bg: #fafafa;
$color-blue: #43dde6;
$color-yellow: #f9d74c;
$color-shadow: #efeeee;
$color-light: #f9f9ff;
$color-light-2: #e2f3f5;
$color-green: #78cc6d;
$color-dark: #364f6b;
$p-color: #949494;
$color-wihte: #fff;
$color-black: #212529;
$color-content: #f9f9ff;
$color-header: #f5f5ff;
$drop-shadow: drop-shadow(0px 0px 10px #f9f9f9);
$border: 1px solid #eae6e6;

// function
@mixin box-shadow($nb, $color) {
    box-shadow: 0px 0px $nb $color;
    -webkit-box-shadow: 0px 0px $nb $color;
    -moz-box-shadow: 0px 0px $nb $color;
    -o-box-shadow: 0px 0px $nb $color;
    -ms-box-shadow: 0px 0px $nb $color;
}

@mixin transition($all, $nb, $ease) {
    transition: $all $nb $ease;
    -webkit-transition: $all $nb $ease;
    -moz-transition: $all $nb $ease;
    -o-transition: $all $nb $ease;
    -ms-transition: $all $nb $ease;
}

@mixin transform($value) {
    transform: $value;
    -webkit-transform: $value;
    -moz-transform: $value;
    -o-transform: $value;
    -ms-transform: $value;
}

@mixin animation($cont) {
    animation: $cont;
    -webkit-animation: $cont;
    -moz-animation: $cont;
    -ms-animation: $cont;
    -o-animation: $cont;
}

@mixin border($op) {
    border: 1px solid $op;
}

// ===================================================



// globals ===========================================
body {
    font-family: $font-1;
    color: $color-black;
    background: $color-content;
    overflow-x: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
}

body.overlay {
    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.6;
        @include transition(all, 0.5s, ease-in-out);
        z-index: 99;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-dark;
    font-family: $font-1;
    font-weight: 700;
    display: block;
}

p {
    color: $p-color;
    font-size: 14px;
    font-family: $font-1;
    line-height: 2;
    font-weight: 400;
}

img {
    max-width: 100%;
    height: auto;
    user-select: none;
    -webkit-user-drag: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: $main-color;

    &:hover {
        text-decoration: none;
        color: $color-dark;
    }
}

::selection {
    background: $main-color;
    color: $color-light;
}


.btn {
    display: inline-flex;
    background: transparent;
    align-items: center;
    height: 50px;
    text-transform: capitalize;
    border: 1px solid $main-color;
    color: $main-color;
    padding: 0 20px;
    font-family: $font-2;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    @include box-shadow(30px, $color-shadow);
    @include transition(all, 0.3s, ease-in-out);

    i,
    svg {
        width: 30px;
        height: 30px;
        // padding-left: 7px;
        @include transition(all, 0.3s, ease-in-out);
    }

    &:hover {
        color: $color-light;
        padding: 0 25px;

        &::before {
            left: 0;
            right: 0;
        }

        i {
            padding-left: 13px;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        left: 51%;
        right: 50%;
        color: $color-light;
        background: $main-color;
        transition: all 0.3s ease-in-out;
        z-index: -1;
        display: block;
    }
}

.btn-mobile-open {
    position: fixed;
    top: 20px;
    // right: 20px;
    z-index: 9999;
    background: transparent;
    border: 0;
    outline: 0;
    display: none;

    i,
    svg {
        width: 40px;
        height: 40px;
        background: $main-color;
        color: $color-light;
        padding: 5px;
        border-radius: 5px;
    }
}

.btn-mobile-close {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 9999;
    background: transparent;
    border: 0;
    outline: 0;
    display: none;

    i,
    svg {
        width: 30px;
        height: 30px;
        background: $main-color;
        color: $color-light;
        padding: 5px;
        border-radius: 5px;
    }
}

body::-webkit-scrollbar {
    width: 10px;
    background: #e1e1f1;
}

body::-webkit-scrollbar-thumb {
    border-radius: 20px;
    transition: all 0.5s ease-in-out;

    &:hover {
        background: rgb(189, 189, 189);
        transition: all 0.5s ease-in-out;
    }
}

.content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    .sections {
        width: 100%;
        height: 100%;

        .link {
            display: none;
        }

        .link.active {
            display: block;
            height: 100%;
        }
    }
}

.box {
    display: none;
}

.box.active {
    display: block;
}

.header {
    height: 100vh;
    width: 296px;
    left: 0;
    top: 0;
    z-index: 9999;

    .header-content {
        height: 100%;
        width: 270px;
        padding: 40px 0;
        padding-left: 25px;
        background: $color-header;
        border-right: 1px solid #f5ebeb;
        position: fixed;
        top: 0;
        z-index: 9999;
    }

    .user {
        width: 100%;
        text-align: left;
        padding-left: 20px;

        .img {
            width: 130px;
            height: 130px;
            border-radius: 100% 50% 50% 100% / 75% 69% 69% 75%;
            padding: 10px;
            margin-bottom: 20px;
            background-color: $color-opacity;
            @include animation(rudios 5s cubic-bezier(0.6, -0.28, 0.55, 0.61) infinite);

            img {
                width: 100%;
                height: 100%;
                border-radius: 100% 50% 50% 100% / 75% 69% 69% 75%;
                @include animation(rudios 5s cubic-bezier(0.6, -0.28, 0.55, 0.61) infinite);
            }

            @keyframes rudios {
                0% {
                    border-radius: 50% 100% 100% 60% / 75% 69% 69% 75%;
                }

                40% {
                    border-radius: 90% 70% 70% 90% / 75% 69% 69% 75%;
                }

                70% {
                    border-radius: 100% 50% 50% 100% / 75% 69% 69% 75%;
                }

                100% {
                    border-radius: 50% 100% 100% 60% / 75% 69% 69% 75%;
                }
            }
        }

        .name {
            color: $main-color;
            font-size: 30px;
            text-transform: capitalize;
            letter-spacing: 1px;
            font-family: $font-1;
        }

        .work {
            span {
                display: block;
                text-transform: capitalize;
                color: $color-dark;
                font-family: $font-1;
            }
        }
    }

    .nav {
        margin-top: 100px;

        .nav-list {
            margin: 0;
            display: block;
            width: 100%;

            li {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                margin-bottom: 3px;

                &:hover a {
                    // animation: btnBarAnimate .3s ease-in-out;
                    color: $main-color;
                    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                }

                a {
                    display: block;
                    text-transform: capitalize;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    font-family: $font-2;
                    color: $color-dark;
                }

                i,
                svg {
                    color: $main-color;
                    // margin-right: 13px;
                    font-size: 17px;
                    display: flex;
                    width: 25px;
                    height: 25px;
                }

                @keyframes btnBarAnimate {
                    0% {
                        opacity: 0;
                        @include transform(scale(1.5));
                    }

                    100% {
                        opacity: 1;
                        @include transform(scale(1));
                    }
                }
            }
        }
    }

    .cpr {
        position: absolute;
        bottom: 0;
        padding: 13px 10px;
        width: 100%;

        span {
            font-size: 14px;
        }
    }

    .download {
        position: absolute;
        top: 50%;
        // right: -20px;
        @include transform(translateY(-50%));
        z-index: 88;

        i,
        svg {
            color: $main-color;
            background: $color-bg;
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid #f5ebeb;
            @include transition(all, 0.5s, ease-in-out);
            position: relative;

            &:hover {
                background: $main-color;
                color: $color-light;
            }
        }

        .hv {
            position: absolute;
            top: 0;
            background: $main-color;
            color: $color-light;
            padding: 5px 10px;
            width: 150px;
            text-transform: capitalize;
            display: inline-flex;
            justify-content: center;
            // margin-left: 20px;
            // @include transform(translateX(-10px));
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;
            @include transition(all, 0.5s, ease-in-out);

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                // border-right: 5px solid $main-color;
                // left: -5px;
                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));
            }
        }

        &:hover .hv {
            @include transform(translateX(0));
            opacity: 1;
            visibility: visible;
        }
    }
}

.pd {
    padding: 3% 6%;
}

.pd-tb {
    padding-top: 4%;
    padding-bottom: 7%;
}

.title {
    h2 {
        text-transform: capitalize;
        font-size: 30px;
        font-family: $font-1;
        letter-spacing: 1px;
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 5px 7px;
        user-select: none;
        z-index: 1;

        &:hover {
            &::after {
                left: 0;
                right: 0;
                width: 100%;
                border-radius: 30px;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 3px;
            // left: -10px;
            width: 40px;
            height: 40px;
            background-color: $color-opacity;
            border-radius: 50%;
            z-index: -1;
            @include transition(all, 0.3s, ease-in-out);
        }
    }
}


// animation
.animate-fade {
    @include animation(fade 1s cubic-bezier(0.25, 1, 0.3, 1));
    transition: opacity 2s ease-in-out;
}

.animate-left {
    @include animation(left 1s cubic-bezier(0.25, 1, 0.3, 1));
}

.animate-right {
    @include animation(right 1s cubic-bezier(0.25, 1, 0.3, 1));
}

.animate-bottom {
    @include animation(bottom 1s cubic-bezier(0.25, 1, 0.3, 1));
}

.animate-top {
    @include animation(top 1s cubic-bezier(0.25, 1, 0.3, 1));
}

@keyframes fade {
    0% {
        @include transform(scale(1.3));
        opacity: 0;
    }

    100% {
        opacity: 1;
        @include transform(scale(1));
    }
}

@keyframes left {
    0% {
        @include transform(translateX(-100px));
    }

    100% {
        @include transform(translateX(0px));
    }
}

@keyframes right {
    0% {
        @include transform(translateX(100px));
    }

    100% {
        @include transform(translateX(0px));
    }
}

@keyframes bottom {
    0% {
        @include transform(translateY(100px));
    }

    100% {
        @include transform(translateY(0px));
    }
}

@keyframes top {
    0% {
        @include transform(translateY(-100px));
    }

    100% {
        @include transform(translateY(0px));
    }
}


// Preloader Style ---------------------
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .preloader-spinner {
        width: 52px;
        height: 52px;
        display: block;
        background-color: $main-color;
        border-radius: 100%;
        @include animation(preloader 1s infinite ease-in-out);
    }
}

@keyframes preloader {
    0% {
        @include transform(scale(0));
    }

    100% {
        @include transform(scale(1.1));
        opacity: 0;
    }
}


.fancybox__container {
    z-index: 99999999999;
}