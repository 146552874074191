// variable --------------
    $color-pink:#e91e63;
    $color-bg: #fafafa;
    $color-blue: #43dde6;
    $color-yellow: #FFB400;
    $color-shadow:#efeeee;
    $color-light:#F9F9FF;
    $color-light-2: #e2f3f5;
    $color-green: #78CC6D;
    $color-dark:#1a1a2c;
    $p-color:#949494;
    $color-wihte:#fff;
    $color-black:#212529;
    $color-content: #06060c;
    $color-header :#0e0e19;
    $drop-shadow:drop-shadow(0px 0px 10px #f9f9f9);
    $border:1px solid #eae6e6;

    // function
    @mixin box-shadow($nb , $color) {
        box-shadow: 0px 0px $nb $color;
            -webkit-box-shadow: 0px 0px $nb $color;
            -moz-box-shadow: 0px 0px $nb $color;
            -o-box-shadow: 0px 0px $nb $color;
            -ms-box-shadow: 0px 0px $nb $color;
    };
    @mixin transition($all, $nb, $ease) {
        transition: $all $nb $ease;
            -webkit-transition: $all $nb $ease;
            -moz-transition: $all $nb $ease;
            -o-transition: $all $nb $ease;
            -ms-transition: $all $nb $ease;
    }
    @mixin transform($value){
        transform:$value;
            -webkit-transform:$value;
            -moz-transform:$value;
            -o-transform:$value;
            -ms-transform:$value;
    }
    @mixin animation($cont) {
        animation: $cont;
            -webkit-animation: $cont;
            -moz-animation: $cont;
            -ms-animation: $cont;
            -o-animation: $cont;
    }
    @mixin border($op) {
        border: 1px solid $op;
    }
// -----x---------x--------

body.dark {
    color: $color-wihte;
    background: $color-content;

    h1,h2,h3,h4,h5,h6 {
        color: $color-light;
    }
    p{
        color: $p-color;
    }
    a{
        color: $color-light;
    }
    .btn{
        box-shadow: none;
    }
    body::-webkit-scrollbar-thumb{
        background: $color-dark;
        box-shadow: none;
    }
    .header{
        .header-content{
            background: $color-header;
            border-right: 1px solid #161212;
        }
        .user{
            .work{
                span{
                    color: $color-light;
                }
            }
        }
        .nav{
            .nav-list{
                li{
                    a{
                        color: $color-light;
                    }
                }
            }
        }
        .download{
            i,svg{
                background: $color-content;
                border: 1px solid $color-header;
            }
        }
    }
    .preloader{
        background: $color-header;
    }
    .about-info{
        .content{
            .info{
                .bottom{
                    .item{
                        .item-content {
                            background: $color-header;
                            box-shadow: none;

                            .title{
                                color: $color-light;
                            }
                        }
                    }
                }
            }
        }
    }
    .fun-fact{
        .fun-fact-content{
            .item{
                .item-content{
                    box-shadow: none;
                    border: none;
                    background: $color-header;
                    .icon{
                        svg{
                            background: $color-dark;
                        }
                    }
                }
            }
        }
    }
    .service-content{
        .item{
            .item-content{
                box-shadow: none;
                border: none;
                background: $color-header;
                &:hover{
                    background: $color-dark;
                }
            }
        }
    }
    .experience{
        .experience-content{
            .experience-bg{
                background: $color-header;
                box-shadow: none;
                .experience-body{
                    background: $color-header;
                    &::-webkit-scrollbar-thumb{
                        background: $color-dark;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .skills-content{
        box-shadow: none;
        background: $color-header;

        .item{
            .title{
                color: $color-wihte;
            }
            .number {
                &::after {
                    border-right-color: $color-header;
                }
            }
        }
    }
    .portfolio{
        .portfolio-content{
            .filter-button{
                button{
                    color: $color-wihte;
                }
            }
            .grid{
                .item{
                    .item-content{
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .portfolio-details{
        background: $color-content;
        .area.left{
            .area-content{
                .item{
                    .item-content{
                        background: $color-header;
                        box-shadow: none;
                        .title{
                            &::after{
                                background-color: $color-content;
                            }
                        }
                        .info{
                            ul.list-block{
                                li{
                                    border-bottom: 1px solid $color-content;
                                    color: $color-light;
                                    a{
                                        color: $color-light;
                                    }
                                    .min{
                                        color: $color-wihte;
                                    }
                                }
                            }
                            ul.list-line{
                                li{
                                    background: $color-content;
                                }
                            }
                        }
                    }
                }
            }
        }
        .area.right{
            .item-content{
                background: $color-header;
                box-shadow: none;
                .title{
                    border-bottom: 1px solid $color-dark;
                }
            }
        }
    }
    .blog{
        .blog-content{
            .item{
                .item-content{
                    box-shadow: none;
                    .img{
                        background:$color-header;
                        border: 15px solid $color-header;
                    }
                    .info{
                        background: $color-header;
                        border: 1px solid $color-header;
                        box-shadow: none;
    
                        .top{
                            span{
                                i,svg{
                                    background:$color-dark;
                                }
                            }
                        }
                        .title{
                            color: $color-wihte;
                        }
                    }
                }
            }
        }
    }
    .blog-details{
        .body{
            .info{
                background: $color-header;
                .info-item{
                    i,svg{
                        background: $color-dark;
                    }
                }
            }
            .post-content{
                background: $color-header;
                box-shadow: none;
                blockquote{
                    background: $color-content;
                }
            }
            .post-comment{
                .title{
                    &::after{
                        background-color: $color-header;
                    }
                }
                .comment-user{
                    background: $color-header;
                    box-shadow: none;
                    .item{
                        .item-img{
                            .item-img-content{
                                background: $color-dark;
                            }
                        }
                        .item-area{
                            background: $color-dark;
                            &::after{
                                border-right: 10px solid $color-dark;
                            }
                            .top{
                                .name{
                                    color: $color-wihte;
                                }
                                .date{
                                    color: $p-color;
                                }
                            }
                            .footer{
                                .link{
                                    background: $color-header;
                                    color: $p-color;
                                }
                            }
                        }
                    }
                }
                .comment-form{
                    background: $color-header;
                    box-shadow: none;
                    form{
                        .item{
                            input{
                                background: $color-dark;
                                border: none;
                                color: $color-light;
                                &:focus{
                                    background: $color-content;
                                }
                            }
                            textarea{
                                background: $color-dark;
                                border: none;
                                color: $color-light;

                                &:focus {
                                    background: $color-content;
                                }
                            }
                            input[type='submit']{
                                background: $color-dark;
                                color:$color-wihte;
                            }
                        }
                    }
                }
            }
        }
        .aside{
            .aside-content{
                .item{
                    box-shadow: none;
                    background: $color-header;
                    .title{
                        &::after{
                            background-color: $color-dark;
                        }
                    }
                    .item-content{
                        .author{
                            .author-info{
                                span{
                                    color: $color-wihte;
                                }
                            }
                        }
                        .search{
                            .input{
                                background: $color-content;
                                input:focus{
                                    color: $color-bg;
                                }
                                i,svg{
                                    background: $color-dark;
                                    color: $color-light;
                                }
                            }
                        }
                        .categories{
                            ul{
                                li{
                                    a{
                                        background: $color-dark;
                                        color: $p-color;
                                    }
                                }
                            }
                        }
                        .recent-posts{
                            .recent-item{
                                .info{
                                    a{
                                        color: $color-wihte;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contact{
        .contact-content{
            .item.left{
                .info{
                    .info-item{
                        background: $color-header;
                        .top{
                            i,svg{
                                background: $color-dark;
                            }
                        }
                    }
                }
            }
            .item.right{
                .item-content{
                    background: $color-header;
                    form{
                        .input{
                            input{
                                background: $color-content;
                                border: 1px solid $color-content;
                            }
                            textarea{
                                background: $color-content;
                                border: 1px solid $color-content;
                            }
                            input[type=submit]{
                                font-size: 14px;
                                border: 1px solid $color-content;
                                background: $color-content;
                                color: $color-light;
                            }
                        }
                    }
                }
            }
        }
    }
    .settings{
        .setting-btn{
            i,svg{
                color: $color-light;
            }
        }
        .setting-content{
            background: $color-header;
            box-shadow: none;

            .title{
                border-bottom: 1px solid $color-content;
                i,svg{
                    background: $color-content;
                }
            }
            .item{
                .item-title{
                    background: $color-content;
                }
                .item-content{
                    span{
                        i,svg{
                            background: $color-content;
                        }
                    }
                    .btn-dir{
                        background: $color-dark;
                        color: $color-light;
                        font-size: 13px;
                        padding: 10px 20px;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
            }
        }
    }
}
