/*-----------------------------------------------------------
* Template Name    : Frindi | Personal Portfolio React Template
* Author           : Mohamed Frindi
* Version          : 1.0.0
* Created          : 2023
------------------------------------------------------------*/


// Color Setting ---------------------
$main-color :#43dde6;
$color-opacity : #43dee62d;
$color-light:#F9F9FF;



body.blue{


    a{
        color: $main-color;
    }
    ::selection{
        background: $main-color;
    }
    .btn{
        border-color:$main-color;
        color: $main-color;
        &:hover{
            color: $color-light;
        }
        &::before{
            background: $main-color;
        }
    }
    .btn-mobile-open{
        i,svg{
            background: $main-color;
        }
    }
    .btn-mobile-close{
        i,svg{
            background: $main-color;
        }
    }
    .header{
        .user{
            .img{
                background: $color-opacity;
            }
            .name{
                color: $main-color;
            }
        }
        .nav{
            .nav-list{
                li{
                    &:hover a{
                        color: $main-color;
                    }
                    i,svg{
                        color: $main-color;
                    }
                }
            }
        }
        .download{
            i,svg{
                color: $main-color;
                &:hover {
                    background: $main-color;
                    color: $color-light;
                }
            }
            .hv{
                background: $main-color;
                &::after{
                    
                    border-right-color: $main-color;
                }
            }
        }
    }
    .title{
        h2{
            &::after{
                background-color: $color-opacity;
            }
        }
    }
    .preloader{
        .preloader-spinner {
            background-color: $main-color;
        }
    }
    .home{
        .item{
            .item-content{
                .im{
                    span{
                        color: $main-color;
                    }
                }
                .socials{
                    ul{
                        li{
                            a{
                                i,svg{
                                    border-color: $main-color;
                                    color: $main-color;

                                    &:hover{    
                                        background: $main-color;
                                        color: $color-light;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .about-info{
        .content{
            .img{
                &::after{
                    border-color:$color-opacity;
                }
            }
            .info{
                .top{
                    h4{
                        span{
                            color: $main-color;
                        }
                        &::before{
                            background: $main-color;
                        }
                    }
                }
            }
        }
    }
    .fun-fact{
        .fun-fact-content{
            .item{
                .item-content{
                    .icon{
                        svg{
                            color: $main-color;
                        }
                    }
                    .info{
                        .number{
                            color: $main-color;
                        }
                    }
                }
            }
        }
    }
    .service-content{
        .item{
            .item-content{
                &:hover{
                    .info{
                        .title{
                            color: $main-color;
                        }
                    }
                }
                .icon{
                    .icon-content{
                        svg{
                            color:$main-color;
                            background: $color-opacity;
                        }
                    }
                }
            }
        }
    }
    .experience{
        .experience-content{
            .experience-bg{
                .experience-body{
                    .item{
                        &::after{
                            background: $main-color;
                        }
                        &::before{
                            content: url('https://api.iconify.design/bytesize/work.svg?color=%2343dde6&height=20');
                        }
                    }
                    .item.study::before{
                        content: url('https://api.iconify.design/fluent:hat-graduation-24-regular.svg?color=%2343DDE6&height=20');
                    }
                }
            }
        }
    }
    .skills-content{
        .item{
            .number{
                border-color:$main-color;
                &::before{
                    border-right-color:$main-color;
                }
            }
            .range{
                background: $color-opacity;
                .range-body{
                    background: $main-color;
                }
            }
        }
    }
    .portfolio{
        .portfolio-content{
            .filter-button{
                button{
                    &:hover{
                        color: $main-color;
                    }
                }
                button.active{
                    color: $main-color;
                }
            }
            .grid{
                .item{
                    .item-content{
                        .overlay{
                            .more-button{
                                svg,i,span{
                                    background: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .blog{
        .blog-content{
            .item{
                .item-content{
                    &:hover{
                        .info{
                            .title{
                                color: $main-color;
                            }
                        }
                    }
                    .info{
                        .top{
                            span{
                                i,svg{
                                    color: $main-color;
                                }
                            }
                        }
                        .title{
                            &:hover{
                                color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-details{
        .body{
            .info{
                span{
                    i,svg{
                        color: $main-color;
                    }
                }
            }
            .post-content{
                blockquote{
                    color: $main-color;
                    &::before{
                        background: $main-color;
                    }
                }
            }
            .post-comment{
                .comment-user{
                    .item{
                        .item-area{
                            .top{
                                .name{
                                    &:hover{
                                        color: $main-color;
                                    }
                                }
                                .date{
                                    i,svg{
                                        color: $main-color;
                                    }
                                }
                            }
                            .footer{
                                .link{
                                    &:hover{
                                        color: $main-color;
                                    }

                                    i,svg{
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .comment-form{
                    form{
                        .item{
                            input[type='submit']{
                                &:hover{
                                    background: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .aside{
            .aside-content{
                .item{
                    .item-content{
                        .categories{
                            ul{
                                li{
                                    a{
                                        &:hover{
                                            color: $main-color;
                                        }
                                    }
                                }
                            }
                        }
                        .recent-posts{
                            .recent-item{
                                .info{
                                    .date{
                                        i,svg{
                                            color: $main-color;
                                        }
                                    }
                                    a{
                                        &:hover{
                                            color: $main-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contact{
        .contact-content{
            .item.left{
                .info{
                    .info-item{
                        .top{
                            i,svg{
                                color: $main-color;
                            }
                        }
                    }
                }
            }
            .item.right{
                .item-content{
                    form{
                        .input{
                            input{
                                color: $main-color;
                                &:focus{
                                    border-color: $main-color;
                                }
                            }
                            textarea{
                                color: $main-color;
                                &:focus{
                                    border-color: $main-color;
                                }
                            }
                            input[type=submit]{
                                &:hover{
                                    background: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .settings{
        .setting-btn{
            i,svg{
                background: $main-color;
            }
        }
    }



}


