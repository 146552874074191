// main RTL style =======================================================
.RTL-style {
    $font-1 : 'Noto Kufi Arabic', sans-serif;

    body {
        font-family: $font-1;
        direction: rtl;
    }


    .btn {

        i,
        svg {
            padding-right: 7px;
        }
    }

    .header {
        .header-content {
            right: 0;
        }

        .user {
            text-align: center;

            .img {
                margin: auto;
                margin-bottom: 20px;
            }

            .name {
                font-weight: 700;
            }

            .work {
                span {
                    font-size: 15px;
                }
            }
        }

        .nav {
            padding-right: 30px;

            .nav-list {

                li {

                    i,
                    svg {
                        margin-left: 13px;
                    }
                }
            }
        }

        .cpr {
            text-align: center;
        }

        .download {
            left: -20px;

            .hv {
                margin-right: 20px;
                @include transform(translateX(10px));

                &::after {
                    border-right: 5px solid;
                    transform: translate(-50%, -50%) rotate(180deg);
                    right: -7px;
                }
            }
        }
    }

    .title {
        font-family: $font-1;

        h2 {
            &::after {
                right: -10px;
            }
        }
    }

    p {
        font-family: $font-1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-1;
    }

    a {
        font-family: Font;
    }

    .btn-mobile-open {
        left: 20px;
    }


    // Home Section Style ------------------
    .home {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;

        .img {
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .item {
            display: flex;
            align-items: center;

            .item-content {
                padding-right: 25%;

                .top {
                    text-transform: uppercase;
                    display: block;
                    margin-bottom: 5px;
                }

                .im {
                    font-size: 30px;

                    span {
                        color: $main-color;
                        text-transform: capitalize;
                    }
                }

                .desc {
                    margin-bottom: 30px;
                }

                .socials {
                    margin-bottom: 30px;

                    ul {
                        display: flex;

                        li {
                            margin-right: 10px;

                            a {

                                i,
                                svg {
                                    width: 40px;
                                    padding: 10px;
                                    height: 40px;
                                    border: 1px solid $main-color;
                                    color: $main-color;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 100% 50% 50% 100% / 75% 69% 69% 75%;

                                    &:hover {
                                        @include animation(btnAnimate 0.3s ease-in-out);
                                        background: $main-color;
                                        color: $color-light;
                                    }

                                    @keyframes btnAnimate {
                                        0% {
                                            opacity: 0;
                                            @include transform(scale(1.5));
                                        }

                                        100% {
                                            opacity: 1;
                                            @include transform(scale(1));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // -----------x-------------x-----------

    // About Section Style -----------------
    .about-cont {
        width: 100%;
    }

    .about-info {
        padding: 7% 0;

        .content {
            .img {
                position: relative;
                z-index: 1;
                padding: 30px 0;

                &::after {
                    content: "";
                    width: 60%;
                    height: 75%;
                    border-radius: 5px;
                    border: 15px solid $color-opacity;
                    position: absolute;
                    background: transparent;
                    top: 0;
                    right: 60px;
                    z-index: -1;
                }

                .img-content {
                    display: flex;
                    justify-content: center;
                }
            }

            .info {
                .top {
                    margin-bottom: 10px;

                    h4 {
                        text-transform: uppercase;
                        font-family: $font-2;
                        position: relative;
                        display: inline-block;

                        span {
                            color: $main-color;
                        }

                        &::after {
                            content: "";
                            width: 100%;
                            height: 2px;
                            background: #ececec;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            z-index: 1;
                        }

                        &::before {
                            content: "";
                            width: 34px;
                            height: 2px;
                            background: $main-color;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            z-index: 2;
                        }
                    }
                }

                .desc {
                    margin-bottom: 50px;
                }

                .bottom {
                    margin-bottom: 20px;

                    // padding-right: 15px;
                    .item {
                        margin-bottom: 50px;
                        position: relative;

                        .item-content {
                            background: $color-wihte;
                            padding: 20px;
                            border-radius: 3px;
                            box-shadow: 0 0 10px #e8e8f1;

                            .title {
                                color: $color-dark;
                                font-weight: 600;
                                text-transform: capitalize;
                                margin-bottom: 3px;
                                margin-top: -7px;
                                font-size: 14px;
                            }

                            span {
                                font-size: 13px;
                                color: $p-color;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    // -----------x-------------x-----------

    // Fun Fact Section Style --------------
    .fun-fact {
        .fun-fact-content {
            .item {
                .item-content {
                    @include box-shadow(20px, $color-shadow);
                    display: flex;
                    text-align: center;
                    height: 90px;
                    border: 1px solid #f1f1f1;
                    background: white;

                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            color: $main-color;
                            background: #fbfbfb;
                            display: block;
                            width: 80px;
                            height: 100%;
                            padding: 25px;
                        }
                    }

                    .info {
                        width: 100%;
                        padding: 7px 0;

                        .number {
                            font-size: 20px;
                            font-weight: 500;
                            color: $main-color;
                        }

                        .title {
                            text-transform: uppercase;
                            font-size: 18px;
                            font-weight: 400;
                            font-family: $font-2;
                        }
                    }
                }
            }
        }
    }

    // -----------x-------------x-----------

    // Service Section Style ---------------
    .service-content {
        .item {
            margin-bottom: 25px;

            .item-content {
                text-align: center;
                padding: 30px 20px;
                @include box-shadow(20px, $color-shadow);
                border: 1px solid #f1f1f1;
                background: white;
                border-radius: 3px;
                @include transition(all, 0.3s, ease-in-out);

                &:hover {
                    background: $color-light;

                    .info {
                        .title {
                            color: $main-color;
                        }
                    }
                }

                .icon {
                    margin-bottom: 10px;

                    .icon-content {
                        svg {
                            display: inline-block;
                            width: 50px;
                            height: 50px;
                            padding: 10px;
                            color: $main-color;
                            margin-bottom: 10px;
                            position: relative;
                            border-radius: 100% 50% 50% 100% / 75% 69% 69% 75%;
                            background: $color-opacity;
                        }
                    }
                }

                .info {
                    .title {
                        text-transform: capitalize;
                        font-size: 18px;
                        font-weight: 500;
                        font-family: $font-1;
                        @include transition(all, 0.3s, ease-in-out);
                    }
                }
            }
        }
    }

    // -----------x-------------x-----------

    // Experience Section Style --------------
    .experience {
        .experience-content {
            padding: {
                top: 10%;
                bottom: 9%;
            }

            .experience-bg {
                background: white;
                padding: 20px;
                border-radius: 20px;
                @include box-shadow(30px, $color-shadow);

                .experience-body {
                    background: white;
                    height: 443px;

                    padding: {
                        left: 95px;
                        top: 30px;
                        right: 30px;
                        bottom: 30px;
                    }

                    border-radius: 20px;
                    overflow-y: hidden;

                    &::-webkit-scrollbar {
                        width: 7px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #dddd;
                        @include box-shadow(20px, $color-shadow);
                        border-radius: 3px;
                    }

                    &:hover {
                        overflow-y: scroll;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    .item {
                        position: relative;

                        padding: {
                            left: 50px;
                            right: 30px;
                        }

                        margin-bottom: 25px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            width: 1px;
                            height: 75%;
                            background: $main-color;
                        }

                        &::before {
                            content: url("https://api.iconify.design/bytesize:work.svg?color=%23e91e63&height=20");
                            position: absolute;
                            right: -7px;
                            top: 3px;
                            display: inline-block;
                        }

                        .top {
                            color: $p-color;
                            font-size: 13px;
                        }

                        .title {
                            font-size: 15px;
                            text-transform: capitalize;
                            font-weight: 600;
                        }

                        .desc {
                            font-size: 13px;
                        }
                    }

                    .item.study::before {
                        content: url("https://api.iconify.design/fluent:hat-graduation-24-regular.svg?color=%23e91e63&height=20");
                    }
                }
            }
        }
    }

    // -----------x-------------x-------------

    // Skills Section Style -----------------
    .skills {
        margin-top: 5%;
    }

    .skills-content {
        overflow: hidden;
        border-radius: 30px;
        padding: 40px;
        box-shadow: 0 0 30px $color-shadow;

        .item {
            margin-bottom: 60px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            .title {
                font-size: 15px;
                font-weight: 500;
                color: $color-dark;
                text-transform: capitalize;
            }

            .number {
                float: right;
                background: transparent;
                padding: 0 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                border: 1px solid $main-color;
                position: absolute;
                top: -5px;
                transform: translateX(50%);
                user-select: none;
                font-size: 13px;

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-right: 6px solid $main-color;
                    position: absolute;
                    right: 50%;
                    bottom: -9px;
                    @include transform(translateX(50%) rotate(-90deg));
                }

                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-right: 6px solid $color-light;
                    position: absolute;
                    right: 50%;
                    bottom: -8px;
                    @include transform(translateX(50%) rotate(-90deg));
                }
            }

            .range {
                width: 100%;
                height: 20px;
                border-radius: 5px;
                background: $color-opacity;
                margin-top: 10px;
                display: flex;
                align-items: center;
                // padding: 0 7px;
                padding-right: 5px;

                .range-body {
                    height: 40%;
                    border-radius: 5px;
                    background: $main-color;
                    display: block;
                }
            }
        }
    }

    // -----------x-------------x-------------

    // Portfolio Section Style --------------
    .portfolio {
        height: 100%;
        width: 100%;

        .portfolio-content {
            .filter-button {
                display: flex;
                margin-bottom: 35px;

                button {
                    border: none;
                    outline: none;
                    color: $color-dark;
                    text-transform: capitalize;
                    margin-right: 50px;
                    padding: 2px 0;
                    border-radius: 5px;
                    background: transparent;
                    font-weight: 600;
                    font-family: $font-1;
                    transition: all 0.3s ease-in-out;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 10px;
                        height: 2px;
                        background: #dddd;
                        border-radius: 30px;
                        z-index: 2;
                        @include transition(all, 0.5s, ease-in-out);
                        opacity: 0;
                        visibility: hidden;
                    }

                    &:hover {
                        color: $main-color;
                    }
                }

                button.active {
                    color: $main-color;
                }
            }

            .grid {
                .item {
                    margin-bottom: 25px;

                    .item-content {
                        width: 100%;
                        height: 250px;
                        text-align: center;
                        position: relative;
                        border-radius: 20px;
                        overflow: hidden;
                        @include box-shadow(10px, $color-shadow);

                        &:hover {
                            .overlay {
                                visibility: visible;
                                opacity: 1;
                                @include transform(scale(1));

                                .title {
                                    opacity: 1;
                                    @include transform(translateY(0px));
                                    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                                    transition-delay: 0.5s;
                                }

                                .term {
                                    opacity: 1;
                                    @include transform(translateX(0px));
                                    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                                    transition-delay: 0.5s;
                                }

                                .more-button {
                                    opacity: 1;
                                    @include transform(translateX(0px));
                                    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                                    transition-delay: 0.5s;
                                }
                            }
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: rgba(0, 0, 0, 0.616);
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            visibility: hidden;
                            opacity: 0;
                            @include transform(scale(0));
                            @include transition(all, 0.5s, ease-in-out);

                            .term {
                                color: $color-bg;
                                padding: 0 20px;
                                height: 25px;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                top: 10px;
                                right: 0;
                                text-transform: capitalize;
                                user-select: none;
                                opacity: 0;
                                @include transform(translateX(30px));
                            }

                            .title {
                                color: $color-bg;
                                font-size: 20px;
                                font-weight: 400;
                                width: 70%;
                                line-height: 1.6;
                                font-family: $font-1;
                                opacity: 0;
                                @include transform(translateY(30px));
                            }

                            .more-button {
                                position: absolute;
                                bottom: 20px;
                                right: 20px;
                                opacity: 0;
                                @include transform(translateX(-30px));

                                svg,
                                i,
                                span {
                                    color: $color-wihte;
                                    background: $main-color;
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    padding: 5px;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .thumb {
                            width: 100%;
                            height: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .portfolio-details {
        width: 100%;
        height: 100%;

        .area.left {
            .area-content {
                position: sticky;
                top: 10px;

                .item {
                    margin-bottom: 20px;

                    .item-content {
                        background: white;
                        padding: 20px 30px;
                        border-radius: 10px;
                        @include box-shadow(30px, $color-shadow);

                        .title {
                            text-transform: capitalize;
                            font-size: 17px;
                            margin-bottom: 30px;
                            padding-bottom: 10px;
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 5px;
                                background-color: $color-header;
                                bottom: 0;
                                right: 0;
                                border-radius: 30px;
                            }
                        }

                        .info {
                            ul.list-block {
                                li {
                                    display: block;
                                    font-size: 13px;
                                    padding: 10px 0;
                                    border-bottom: 1px solid #f1f1f1;
                                    color: $p-color;

                                    a {
                                        color: $p-color;
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    .min {
                                        text-transform: capitalize;
                                        font-size: 13px;
                                        width: 80px;
                                        color: $color-black;
                                        font-weight: 400;
                                        display: inline-flex;
                                        position: relative;
                                        margin-right: 40px;

                                        &::after {
                                            content: ":";
                                            position: absolute;
                                            right: 0;
                                        }
                                    }
                                }
                            }

                            ul.list-line {
                                display: flex;
                                flex-wrap: wrap;

                                li {
                                    border: 0;
                                    margin-right: 10px;
                                    background: $color-content;
                                    padding: 5px 10px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .area.right {
            .item-content {
                background: white;
                padding: 20px 30px;
                border-radius: 10px;
                @include box-shadow(30px, $color-shadow);

                div {
                    margin-bottom: 60px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .title {
                    margin-bottom: 15px;
                    font-size: 17px;
                    font-weight: 500;
                    text-transform: capitalize;
                    padding-bottom: 15px;
                    border-bottom: 1px solid whitesmoke;
                }

                .desc {
                    font-size: 14px;
                    padding-left: 10px;
                }

                .list {
                    padding-right: 30px;
                    margin-bottom: 20px;

                    li {
                        color: $p-color;
                        position: relative;

                        &::before {
                            content: url("https://api.iconify.design/grommet-icons:validate.svg?color=%23949494&height=15");
                            margin-left: 20px;
                        }
                    }
                }

                img {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // -----------x-------------x------------

    // Blog Section Style -------------------
    .blog {
        width: 100%;

        .blog-content {
            text-align: center;

            .item {
                margin-bottom: 170px;

                .item-content {
                    @include box-shadow(20px, $color-shadow);
                    position: relative;

                    &:hover {
                        .img {
                            img {
                                @include transform(scale(1.2) rotate(5deg));
                                filter: grayscale(1) blur(1px);
                            }
                        }

                        .info {
                            .title {
                                color: $main-color;
                            }
                        }
                    }

                    .img {
                        border-radius: 10px;
                        overflow: hidden;
                        height: 370px;
                        background: #e1e1ff;
                        border: 15px solid #e1e1ff;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 3px;
                            @include transition(all, 0.5s, ease-in-out);
                        }

                        a {
                            padding: 0;
                        }
                    }

                    .info {
                        padding: 20px 20px;
                        background: $color-wihte;
                        box-shadow: 0px 21px 29px -20px #9e9e9e;
                        border-radius: 10px;
                        width: 80%;
                        position: absolute;
                        bottom: -80px;
                        left: 50%;
                        border: 1px solid #e1e1ff;
                        text-align: right;
                        @include transform(translateX(-50%));

                        .top {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;

                            span {
                                margin-left: 25px;
                                color: $p-color;
                                font-size: 13px;

                                i,
                                svg {
                                    color: $main-color;
                                    width: 17px;
                                    height: 17px;
                                    background: $color-light-2;
                                    padding: 3px;
                                    margin-top: -2px;
                                    margin-left: 0px;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .title {
                            line-height: 1.7;
                            font-size: 19px;
                            font-weight: 600;
                            color: $color-dark;
                            @include transition(all, 0.5s, ease-in-out);

                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // blog-details
    .blog-details {
        width: 100%;
        height: 100%;

        .body {
            .img {
                width: 100%;
                height: 430px;
                overflow: hidden;
                border-radius: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .info {
                margin-bottom: 20px;
                margin-top: 20px;
                background: white;
                padding: 20px 10px;
                border-radius: 10px;

                span {
                    color: $p-color;
                    margin-right: 15px;
                    font-size: 13px;

                    i,
                    svg {
                        color: $main-color;
                        width: 25px;
                        height: 25px;
                        background: $color-content;
                        padding: 5px;
                        border-radius: 50%;
                        margin-top: -3px;
                    }
                }
            }

            .post-content {
                background: white;
                padding: 20px 20px;
                border-radius: 10px;
                @include box-shadow(30px, $color-shadow);

                p {
                    font-size: 14px;
                }

                blockquote {
                    background: $color-content;
                    position: relative;
                    padding: 20px;
                    padding-right: 100px;
                    color: $main-color;

                    &::before {
                        content: "";
                        width: 50px;
                        height: 2px;
                        background: $main-color;
                        position: absolute;
                        right: 10px;
                        top: 34px;
                    }
                }

                img {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }

            .post-comment {
                margin-top: 20px;

                .title {
                    text-transform: capitalize;
                    font-size: 20px;
                    position: relative;
                    padding-bottom: 10px;
                    margin-bottom: 30px;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 20px;
                        height: 5px;
                        background-color: $color-header;
                        bottom: 0;
                        right: 0;
                        border-radius: 30px;
                    }
                }

                .comment-user {
                    background: $color-wihte;
                    padding: 20px 20px;
                    border-radius: 10px;
                    @include box-shadow(30px, $color-shadow);

                    .item {
                        display: flex;
                        margin-bottom: 10px;

                        .item-img {
                            margin-left: 20px;

                            .item-img-content {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                padding: 5px;
                                background: $color-content;
                                margin-top: 10px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .item-area {
                            background: $color-header;
                            position: relative;
                            padding: 20px 20px;
                            border-radius: 10px;
                            width: 100%;

                            &::after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-top: 10px solid transparent;
                                border-bottom: 10px solid transparent;
                                border-left: 10px solid $color-header;
                                position: absolute;
                                right: -10px;
                                top: 25%;
                                transform: translateY(-50%);
                            }

                            .top {
                                .name {
                                    text-transform: capitalize;
                                    font-size: 13px;
                                    font-weight: 500;
                                    color: $color-dark;
                                    display: block;
                                    font-family: $font-1;
                                    margin-bottom: -5px;

                                    &:hover {
                                        color: $main-color;
                                    }
                                }

                                .date {
                                    font-size: 12px;
                                    color: $color-dark;

                                    i,
                                    svg {
                                        color: $main-color;
                                        margin-top: -2px;
                                        margin-right: 3px;
                                    }
                                }
                            }

                            .body {
                                margin-bottom: 10px;

                                .desc {
                                    font-size: 13px;
                                    margin-bottom: 0;
                                }
                            }

                            .footer {
                                display: flex;
                                .link {
                                    text-transform: capitalize;
                                    color: $color-dark;
                                    margin-left: 5px;
                                    font-size: 11px;
                                    padding: 0px 7px;
                                    background: #ebebfb;
                                    border-radius: 10px;
                                    height: 23px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-family: $font-1;

                                    &:hover {
                                        color: $main-color;
                                    }

                                    i,
                                    svg {
                                        color: $main-color;
                                        margin-top: -3px;
                                        width: 20px;
                                        height: 20px;
                                        padding: 3px;
                                    }
                                }
                            }
                        }
                    }

                    .item.reply {
                        margin-right: 50px;
                    }
                }

                .comment-form {
                    margin-top: 30px;
                    background: $color-wihte;
                    padding: 20px 20px;
                    border-radius: 10px;
                    @include box-shadow(30px, $color-shadow);

                    form {
                        .item {
                            margin-bottom: 10px;
                            padding: 0 5px;

                            input {
                                width: 100%;
                                background: $color-content;
                                border: 1px solid #eae5e5;
                                height: 50px;
                                padding: 0 10px;
                                font-size: 14px;
                                border-radius: 5px;
                                @include transition(all, 0.3s, ease-in-out);

                                &::placeholder {
                                    font-size: 13px;
                                    text-transform: capitalize;
                                }

                                &:focus {
                                    outline: 0;
                                    background: white;
                                }
                            }

                            textarea {
                                @extend input;
                                height: 200px;
                                padding-top: 10px;
                            }

                            input[type="submit"] {
                                text-transform: capitalize;
                                background: $color-header;
                                color: $color-dark;
                                @include transition(all, 0.5s, ease-in-out);

                                &:hover {
                                    background: $main-color;
                                    color: $color-light;
                                }
                            }
                        }
                    }
                }
            }
        }

        .aside {
            .aside-content {
                position: sticky;
                top: 20px;

                .item {
                    @include box-shadow(30px, $color-shadow);
                    border-radius: 10px;
                    margin-bottom: 25px;
                    background: white;
                    padding: 10px;

                    .title {
                        font-size: 17px;
                        text-transform: capitalize;
                        display: inline-flex;
                        position: relative;
                        padding-bottom: 10px;

                        &::after {
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 5px;
                            background-color: $color-header;
                            bottom: 0;
                            border-radius: 30px;
                        }
                    }

                    .item-content {
                        padding: 30px 10px;

                        .author {
                            display: flex;

                            .author-img {
                                margin-left: 30px;

                                .author-img-content {
                                    height: 80px;
                                    width: 80px;
                                    border: 2px solid $color-header;
                                    border-radius: 50%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 50%;
                                    }
                                }
                            }

                            .author-info {
                                span {
                                    font-size: 17px;
                                    text-transform: capitalize;
                                    color: $color-dark;
                                }

                                .desc {
                                    font-size: 13px;
                                }
                            }
                        }

                        .search {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .input {
                                height: 50px;
                                background: $color-content;
                                display: flex;
                                align-items: center;
                                padding-right: 10px;
                                width: 100%;

                                input {
                                    border: 0;
                                    background: transparent;
                                    width: 100%;

                                    &:focus {
                                        outline: 0;
                                    }

                                    &::placeholder {
                                        font-size: 13px;
                                    }
                                }

                                i,
                                svg {
                                    background: #f2f2ff;
                                    width: 50px;
                                    height: 100%;
                                    padding: 13px;
                                    color: $color-dark;
                                    cursor: pointer;
                                }
                            }
                        }

                        .categories {
                            ul {
                                display: inline;

                                li {
                                    display: inline-flex;
                                    margin-right: 5px;
                                    margin-bottom: 5px;

                                    a {
                                        background: $color-header;
                                        color: $color-dark;
                                        text-transform: capitalize;
                                        padding: 5px 10px;
                                        font-size: 12px;
                                        font-family: $font-1;
                                        @include transition(all,
                                            0.3s,
                                            ease-in-out);
                                    }
                                }
                            }
                        }

                        .recent-posts {
                            .recent-item {
                                margin-bottom: 20px;
                                display: flex;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                .img {
                                    flex: 1;

                                    .img-content {
                                        width: 80%;
                                        height: 70px;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }

                                .info {
                                    flex: 2;

                                    .date {
                                        display: block;
                                        color: $p-color;
                                        font-size: 13px;

                                        i,
                                        svg {
                                            color: $main-color;
                                            margin-top: -2px;
                                        }
                                    }

                                    a {
                                        font-size: 15px;
                                        width: 70%;
                                        color: $color-black;
                                        font-weight: 500;
                                        font-family: $font-1;
                                        @include transition(all,
                                            0.3s,
                                            ease-in-out);

                                        &:hover {
                                            color: $main-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // -----------x-------------x------------

    // Contact Section Style -------------------
    .contact {
        width: 100%;

        .contact-content {
            .item.left {
                .title {
                    text-transform: capitalize;
                }

                .desc {
                    width: 80%;
                    font-size: 14px;
                    margin-bottom: 20px;
                }

                .info {
                    .info-item {
                        background: white;
                        margin-bottom: 13px;
                        border-radius: 10px;
                        padding: 20px;

                        .top {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;

                            i,
                            svg {
                                color: $main-color;
                                width: 30px;
                                height: 30px;
                                padding: 5px;
                                background: $color-content;
                                border-radius: 50%;
                                margin-left: 10px;
                            }

                            .info-title {
                                text-transform: capitalize;
                                margin-top: 10px;
                                font-size: 15px;
                            }
                        }

                        .area {
                            padding-left: 20px;

                            span {
                                display: block;
                                font-size: 14px;
                                color: $p-color;
                            }
                        }
                    }
                }
            }

            .item.right {
                .item-content {
                    background: white;
                    padding: 20px 15px;
                    border-radius: 10px;

                    form {
                        .input {
                            margin-bottom: 10px;
                            position: relative;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            input {
                                background: $color-content;
                                width: 100%;
                                height: 50px;
                                padding: 0 10px;
                                border: none;
                                color: $main-color;
                                font-size: 14px;
                                border: 1px solid $color-shadow;
                                @include transition(all, 0.3s, ease-in-out);

                                &:focus {
                                    outline: 0;
                                    border-color: $main-color;
                                    border-radius: 0;
                                }

                                &::placeholder {
                                    text-transform: capitalize;
                                    font-family: $font-1;
                                    font-size: 13px;
                                }
                            }

                            textarea {
                                @extend input;
                                height: 185px;
                                padding-top: 10px;
                                resize: none;
                                // border: none;
                            }

                            input[type="submit"] {
                                font-size: 14px;
                                background: $color-header;
                                border-radius: 5px;
                                border: 1px solid $color-shadow;
                                color: $color-dark;
                                @include transition(all, 0.3s, ease-in-out);

                                &:hover {
                                    background: $main-color;
                                    color: $color-light;
                                }
                            }
                        }

                        .input.remove-pd {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    // -----------x-------------x------------

    // setting style ------------------------
    .settings {
        width: 300px;
        position: fixed;
        top: 50%;
        left: -300px;
        @include transform(translateY(-50%));
        @include transition(all, 0.5s, cubic-bezier(0.4, 0, 0.06, 1.14));
        z-index: 99;

        .setting-btn {
            background: none;
            border: 0;
            position: absolute;
            right: -50px;
            top: 30%;
            @include transform(translateY(-50%));

            i,
            svg {
                background: $main-color;
                color: $color-light;
                border-right: none;
                border: 0;
                width: 50px;
                height: 50px;
                padding: 10px;
            }
        }

        .setting-content {
            background: $color-wihte;
            @include box-shadow(30px, $color-shadow);

            .title {
                text-align: center;
                font-size: 17px;
                display: block;
                padding: 20px 0;
                text-transform: capitalize;
                position: relative;
                border-bottom: 1px solid $color-header;

                i,
                svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 60px;
                    padding: 19px;
                    background: transparent;
                    display: none;
                }
            }

            .item {
                padding: 10px 20px;
                margin-bottom: 10px;

                .item-title {
                    font-size: 13px;
                    display: block;
                    padding: 10px 10px;
                    margin-bottom: 20px;
                    background: $color-header;
                }

                .item-content {
                    text-align: center;

                    span {
                        display: inline-flex;
                        margin-right: 7px;

                        i,
                        svg {
                            width: 45px;
                            height: 45px;
                            cursor: pointer;
                            padding: 10px;
                            background: $color-content;
                        }
                    }

                    .color {
                        display: inline-flex;
                        width: 30px;
                        height: 30px;
                        margin-right: 5px;
                        border-radius: 50%;
                        border: 0;
                        cursor: pointer;
                    }

                    .color.pink {
                        background: $color-pink;
                    }

                    .color.blue {
                        background: $color-blue;
                    }

                    .color.green {
                        background: $color-green;
                    }

                    .color.yellow {
                        background: $color-yellow;
                    }

                    .btn-dir {
                        background: $color-header;
                        color: $color-dark;
                        font-size: 13px;
                        padding: 10px 20px;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
            }
        }
    }

    .settings.active {
        left: 0;
    }

    // ------------x-------------x-----------

    // fixed problem dark mode ----------------------------
    body.dark {
        .blog-details .body .post-comment .comment-user .item .item-area::after {
            border-left: none;
            @include transform(rotate(180deg));
        }

        .contact .contact-content .item.right .item-content form .input input,
        body.dark .contact .contact-content .item.right .item-content form .input textarea {
            border: none;
        }
    }

    // ---------------x----------------x---------------


    // fix broblems responsive RTL ----------------------------
    @media (max-width: 575px) {

        // header style -------------------------
        .header {
            right: -100%;

            .header-content {
                right: -100%;
            }

            .header-content.active {
                right: 0;
            }
        }

        .header.acive {
            right: 0;
        }

        // ------------x---------------x------------
    }

    @media (min-width: 576px) and (max-width: 767.98px) {

        // header style -------------------------
        .header {
            right: -100%;

            .header-content {
                right: -100%;
            }

            .header-content.active {
                right: 0;
            }
        }

        .header.acive {
            right: 0;
        }

        // ------------x---------------x------------
    }

    @media (min-width: 768px) and (max-width: 991.98px) {

        // header style -------------------------
        .header {
            right: -100%;

            .header-content {
                right: -100%;
            }

            .header-content.active {
                right: 0;
            }
        }

        .header.acive {
            right: 0;
        }

        // ------------x---------------x------------

        // Home Section Style ------------------
        .home {
            text-align: right;

            .row {
                flex-wrap: wrap-reverse;
            }

            .item {
                display: flex;
                align-items: center;

                .item-content {
                    padding: 0 40px;

                    .top {
                        font-size: 13px;
                        text-align: right;
                    }

                    .im {
                        font-size: 25px;
                        text-align: right;
                    }

                    .desc {
                        margin-bottom: 25px;
                        font-size: 13px;
                        text-align: right;
                    }
                }
            }
        }

        // -----------x-------------x-----------
    }

    @media (min-width: 992px) and (max-width: 1299.99px) {

        // header style -------------------------
        .header {
            right: -100%;

            .header-content {
                right: -100%;
            }

            .header-content.active {
                right: 0;
            }
        }

        .header.acive {
            right: 0;
        }

        // ------------x---------------x------------

        // Home Section Style ------------------
        .home {
            text-align: right;

            .row {
                flex-wrap: wrap-reverse;
            }

            .item {
                display: flex;
                align-items: center;

                .item-content {
                    padding: 0 40px;

                    .top {
                        font-size: 13px;
                        text-align: right;
                    }

                    .im {
                        font-size: 25px;
                        text-align: right;
                    }

                    .desc {
                        margin-bottom: 25px;
                        font-size: 13px;
                        text-align: right;
                    }
                }
            }
        }

        // -----------x-------------x-----------
    }

    @media (min-width: 1300px) {
        .btn-mobile-open {
            display: none;
        }

        .btn-mobile-close {
            display: none;
        }
    }

}