// vars 
// @import "./_vars.scss";

// responsive ===========================================================

.LTR-style , .RTL-style {

/*  Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {



// gineral style -------------------
    .pd{
        padding: 4% 20px;
    }
    .pd-tb{
        padding-top: 10%;
        padding-bottom: 15%;
    }
    .title{
    
        h2{
            font-size: 25px;
        }
    }
// ---------x-------------x------------


// header style -------------------------
    .header{
        width: 296px;
        left: -100%;
        top: 0;
        z-index: 9999;
        position: fixed;
        @include transition(all,.3s,ease-in-out);

        .header-content{
            width: 250px;
            padding:40px 0;
            padding-left: 10px;
            position: fixed;
            left: -100%;
            @include transition(all,.3s,ease-in-out);
        }
        .header-content.active{
            left: 0;
        }
    }
    .header.acive{
        left: 0;
    }
    .btn-mobile-open{
        display: block;
    }
    .btn-mobile-close{
        display: block;
    }
// ------------x---------------x------------



// Home Section Style ------------------
    .home{
        text-align: center;
        height: 100%;
        .row{
            flex-wrap: wrap-reverse;
        }
        .item{
            display: flex;
            align-items: center;

            .item-content{
                padding:0 40px;

                .top{
                    font-size: 13px;
                }
                .im{
                    font-size: 25px;
                }
                .desc{
                    margin-bottom: 25px;
                    font-size: 13px;
                }
                .socials{
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
// -----------x-------------x-----------



// About Section Style -----------------
    .about-info{
        padding: 10% 0;
        .content{
            .img{
                &::after{
                    width: 70%;
                }
            }
            .info{
                
                .top{
                    h4{
                        font-size: 17px;
                    }
                }
                .desc{
                    margin-bottom: 50px;
                    p{
                        font-size: 13px;
                    }
                }
                .bottom{
                    margin-bottom: 20px;
                    padding-left: 10px;
                    .item{
                        margin-bottom: 50px;
                        position: relative;
                        width: 50%;

                        .title{
                            font-weight: 400;
                            font-size: 14px;
                        }
                        span{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Fun Fact Section Style --------------
    .fun-fact{
        .fun-fact-content{
            .item{
                margin-bottom: 20px;
            }
        }
    }
// -----------x-------------x-----------



// Experience Section Style --------------
    .experience{
        .experience-content{
            .experience-bg{
                padding: 20px;
                .experience-body{
                    height: auto;
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                    
                    .item{
                        padding: {
                            left: 30px;
                            right: 30px;
                        };
                        margin-bottom: 25px;

                        &::before{
                            top: 2px;
                        }
                        .top{
                            font-size: 12px;
                        }
                        .title{
                            font-size: 15px;
                        }
                        .desc{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-------------



// Skills Section Style -----------------
    .skills-content{
        padding: {
            top: 11%;
            bottom: 6%;
            left: 0%;
        };
    }
// -----------x-------------x-------------




// Portfolio Section Style --------------
    .portfolio{
        height: 100%;

        .portfolio-content{
            .filter-button{
                display: flex;
                margin-bottom: 35px;
                button{
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
            .grid{
                .item{
                    width: 100%;
                    .item-content{
                        width: 100%;
                        height: 250px;

                        .thumb{
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-details{
        background: $color-content;
        width: 100%;
        height: 100%;

        .area.left{
            margin-bottom: 30px;
        }
    }
// -----------x-------------x------------



// Blog Section Style -------------------
    .blog{
        width: 100%;
        height: 100%;

        .blog-content{
            text-align: center;

            .item{

                .item-content{

                    .img{
                        height: 220px;
                    }
                    .info{
                        width: 100%;
                        .top{
                            span{
                                font-size: 12px;

                                i,svg{
                                    width: 20px;
                                    height: 20px;
                                    padding: 5px;
                                }
                            }
                        }
                        .title{
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    // blog-details
    .blog-details{
        width: 100%;
        height: 100%;

        .body{
            margin-bottom: 30px;
            .img{
                width: 100%;
                height: 200px;
            }
            .post-comment{
                .comment-user{
                    .item{
                        .item-area{

                            .top{
                                .name{  
                                    font-size: 13px;
                                }
                                .date{
                                    font-size: 12px;
                                }
                            }
                            .body{
                                margin-bottom: 5px;
                                .desc{
                                    font-size: 12px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .item.reply{
                        margin-left: 0px;
                    }
                }
            }
        }
    }
// -----------x-------------x------------



// Contact Section Style -------------------
    .contact{
        .contact-content{
            .item.left{
                margin-bottom: 30px;
            }
        }
    }
// -----------x-------------x------------




}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {




// gineral style -------------------
    .pd{
        padding: 4% 20px;
    }
    .pd-tb{
        padding-top: 10%;
        padding-bottom: 15%;
    }
    .title{

        h2{
            font-size: 25px;
        }
    }
// ---------x-------------x------------


// header style -------------------------
    .header{
        width: 296px;
        left: -100%;
        top: 0;
        z-index: 9999;
        position: fixed;
        @include transition(all,.3s,ease-in-out);

        .header-content{
            width: 250px;
            padding:40px 0;
            padding-left: 10px;
            position: fixed;
            left: -100%;
            @include transition(all,.3s,ease-in-out);
        }
        .header-content.active{
            left: 0;
        }
    }
    .header.acive{
        left: 0;
    }
    .btn-mobile-open{
        display: block;
    }
    .btn-mobile-close{
        display: block;
    }
// ------------x---------------x------------



// Home Section Style ------------------
    .home{
        text-align: center;
        .row{
            flex-wrap: wrap-reverse;
        }
        .item{
            display: flex;
            align-items: center;

            .item-content{
                padding:0 40px;

                .top{
                    font-size: 13px;
                }
                .im{
                    font-size: 25px;
                }
                .desc{
                    margin: auto;
                    margin-bottom: 25px;
                    font-size: 13px;
                    width: 80%;
                }
                .socials{
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
// -----------x-------------x-----------



// About Section Style -----------------
    .about-info{
        padding: 10% 0;
        .content{
            .img{
                &::after{
                    width: 50%;
                }
            }
            .info{
                
                .top{
                    h4{
                        font-size: 18px;
                    }
                }
                .desc{
                    margin-bottom: 50px;
                    p{
                        font-size: 14px;
                    }
                }
                .bottom{
                    margin-bottom: 20px;
                    padding-left: 10px;
                    .item{
                        margin-bottom: 50px;
                        position: relative;
                        width: 50%;

                        .title{
                            font-weight: 400;
                            font-size: 14px;
                        }
                        span{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Fun Fact Section Style --------------
    .fun-fact{
        .fun-fact-content{
            .item{
                margin-bottom: 20px;
            }
        }
    }
// -----------x-------------x-----------




// Fun Fact Section Style --------------
    .service-content{
        .item{
            .item-content{
                .info{
                    .desc{
                        width: 80%;
                        margin: auto;
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Experience Section Style --------------
    .experience{
        .experience-content{
            .experience-bg{
                padding: 20px;
                .experience-body{
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                    
                    .item{
                        padding: {
                            left: 30px;
                            right: 30px;
                        };
                        margin-bottom: 25px;

                        &::before{
                            top: 2px;
                        }
                        .top{
                            font-size: 12px;
                        }
                        .title{
                            font-size: 15px;
                        }
                        .desc{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-------------



// Skills Section Style -----------------
    .skills-content{
        padding: {
            top: 11%;
            bottom: 6%;
            left: 0%;
        };
    }
// -----------x-------------x-------------




// Portfolio Section Style --------------
    .portfolio{

        .portfolio-content{
            .filter-button{
                display: flex;
                margin-bottom: 35px;
                button{
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
            .grid{
                .item{
                    width: 100%;
                    .item-content{
                        width: 100%;
                        height: 350px;

                        .thumb{
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-details{
        background: $color-content;
        width: 100%;
        height: 100%;

        .area.left{
            margin-bottom: 30px;
        }
    }
// -----------x-------------x------------



// Blog Section Style -------------------
    .blog{
        width: 100%;
        height: 100%;

        .blog-content{
            text-align: center;

            .item{

                .item-content{

                    .img{
                        height: 350px;
                    }
                    .info{
                        .top{
                            span{
                                font-size: 12px;

                                i,svg{
                                    width: 20px;
                                    height: 20px;
                                    padding: 5px;
                                }
                            }
                        }
                        .title{
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    // blog-details
    .blog-details{
        width: 100%;
        height: 100%;

        .body{
            margin-bottom: 30px;
            .img{
                width: 100%;
                height: 350px;
            }
            .post-comment{
                .comment-user{
                    .item{
                        .item-area{

                            .top{
                                .name{  
                                    font-size: 13px;
                                }
                                .date{
                                    font-size: 12px;
                                }
                            }
                            .body{
                                margin-bottom: 5px;
                                .desc{
                                    font-size: 12px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .item.reply{
                        margin-left: 0px;
                    }
                }
            }
        }
    }
// -----------x-------------x------------



// Contact Section Style -------------------
    .contact{
        .contact-content{
            .item.left{
                margin-bottom: 30px;
            }
        }
    }
// -----------x-------------x------------




}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {



// gineral style -------------------
    .pd{
        padding: 4% 20px;
    }
    .pd-tb{
        padding-top: 10%;
        padding-bottom: 15%;
    }
    .title{

        h2{
            font-size: 25px;
        }
    }
// ---------x-------------x------------


// header style -------------------------
    .header{
        width: 296px;
        left: -100%;
        top: 0;
        z-index: 9999;
        position: fixed;
        @include transition(all,.3s,ease-in-out);

        .header-content{
            width: 250px;
            padding:40px 0;
            padding-left: 10px;
            position: fixed;
            left: -100%;
            @include transition(all,.3s,ease-in-out);
        }
        .header-content.active{
            left: 0;
        }
    }
    .header.acive{
        left: 0;
    }
    .btn-mobile-open{
        display: block;
    }
    .btn-mobile-close{
        display: block;
    }
// ------------x---------------x------------



// Home Section Style ------------------
    .home{
        text-align: left;
        .row{
            flex-wrap: wrap-reverse;
        }
        .item{
            display: flex;
            align-items: center;

            .item-content{
                padding:0 40px;

                .top{
                    font-size: 13px;
                }
                .im{
                    font-size: 25px;
                }
                .desc{
                    margin-bottom: 25px;
                    font-size: 13px;
                }
            }
        }
    }
// -----------x-------------x-----------



// About Section Style -----------------
    .about-info{
        padding: 10% 0;
        .content{
            .img{
                &::after{
                    width: 70%;
                }
            }
            .info{
                
                .top{
                    h4{
                        font-size: 18px;
                    }
                }
                .desc{
                    margin-bottom: 50px;
                    p{
                        font-size: 14px;
                    }
                }
                .bottom{
                    margin-bottom: 20px;
                    padding-left: 10px;
                    .item{
                        margin-bottom: 50px;
                        position: relative;
                        width: 50%;

                        .title{
                            font-weight: 400;
                            font-size: 14px;
                        }
                        span{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Fun Fact Section Style --------------
    .fun-fact{
        .fun-fact-content{
            .item{
                margin-bottom: 20px;
            }
        }
    }
// -----------x-------------x-----------




// Fun Fact Section Style --------------
    .service-content{
        .item{
            .item-content{
                .info{
                    .desc{
                        width: 80%;
                        margin: auto;
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Experience Section Style --------------
    .experience{
        .experience-content{
            .experience-bg{
                padding: 20px;
                .experience-body{
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                    
                    .item{
                        padding: {
                            left: 30px;
                            right: 30px;
                        };
                        margin-bottom: 25px;

                        &::before{
                            top: 2px;
                        }
                        .top{
                            font-size: 12px;
                        }
                        .title{
                            font-size: 15px;
                        }
                        .desc{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-------------



// Skills Section Style -----------------
    .skills-content{
        padding: {
            top: 11%;
            bottom: 6%;
            left: 0%;
        };
    }
// -----------x-------------x-------------




// Portfolio Section Style --------------
    .portfolio{

        .portfolio-content{
            .filter-button{
                display: flex;
                margin-bottom: 35px;
                button{
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
            .grid{
                .item{
                    .item-content{
                        width: 100%;
                        height: 250px;

                        .thumb{
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-details{
        background: $color-content;
        width: 100%;
        height: 100%;

        .area.left{
            margin-bottom: 30px;
        }
    }
// -----------x-------------x------------



// Blog Section Style -------------------
    .blog{
        width: 100%;
        height: 100%;

        .blog-content{
            text-align: center;

            .item{

                .item-content{

                    .img{
                        height: 270px;
                    }
                    .info{
                        .top{
                            span{
                                font-size: 12px;

                                i,svg{
                                    width: 20px;
                                    height: 20px;
                                    padding: 5px;
                                }
                            }
                        }
                        .title{
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    // blog-details
    .blog-details{
        width: 100%;
        height: 100%;

        .body{
            margin-bottom: 30px;
            .img{
                width: 100%;
                height: 450px;
            }
            .post-comment{
                .comment-user{
                    .item{
                        .item-area{

                            .top{
                                .name{  
                                    font-size: 13px;
                                }
                                .date{
                                    font-size: 12px;
                                }
                            }
                            .body{
                                margin-bottom: 5px;
                                .desc{
                                    font-size: 12px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .item.reply{
                        margin-left: 0px;
                    }
                }
            }
        }
    }
// -----------x-------------x------------



// Contact Section Style -------------------
    .contact{
        .contact-content{
            .item.left{
                margin-bottom: 30px;
            }
        }
    }
// -----------x-------------x------------




}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1299.99px) {



// gineral style -------------------
    .pd{
        padding: 4% 20px;
    }
    .pd-tb{
        padding-top: 5%;
        padding-bottom: 7%;
    }
    .title{

        h2{
            font-size: 25px;
        }
    }
// ---------x-------------x------------


// header style -------------------------
    .header{
        width: 296px;
        left: -100%;
        top: 0;
        z-index: 9999;
        position: fixed;
        @include transition(all,.3s,ease-in-out);

        .header-content{
            width: 250px;
            padding:40px 0;
            padding-left: 10px;
            position: fixed;
            left: -100%;
            @include transition(all,.3s,ease-in-out);
        }
        .header-content.active{
            left: 0;
        }
    }
    .header.acive{
        left: 0;
    }
    .btn-mobile-open{
        display: block;
    }
    .btn-mobile-close{
        display: block;
    }
// ------------x---------------x------------



// Home Section Style ------------------
    .home{
        text-align: left;
        .row{
            flex-wrap: wrap-reverse;
        }
        .item{
            display: flex;
            align-items: center;

            .item-content{
                padding:0 40px;

                .top{
                    font-size: 13px;
                }
                .im{
                    font-size: 25px;
                }
                .desc{
                    margin-bottom: 25px;
                    font-size: 13px;
                }
            }
        }
    }
// -----------x-------------x-----------



// About Section Style -----------------
    .about-info{
        padding: 5% 0;
        .content{
            .img{
                &::after{
                    width: 70%;
                }
            }
            .info{
                
                .top{
                    h4{
                        font-size: 18px;
                    }
                }
                .desc{
                    margin-bottom: 50px;
                    p{
                        font-size: 14px;
                    }
                }
                .bottom{
                    margin-bottom: 20px;
                    padding-left: 10px;
                    .item{
                        margin-bottom: 50px;
                        position: relative;
                        width: 50%;

                        .title{
                            font-weight: 400;
                            font-size: 14px;
                        }
                        span{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Fun Fact Section Style --------------
    .fun-fact{
        .fun-fact-content{
            .item{
                margin-bottom: 20px;
            }
        }
    }
// -----------x-------------x-----------




// Fun Fact Section Style --------------
    .service-content{
        .item{
            .item-content{
                .info{
                    .desc{
                        width: 80%;
                        margin: auto;
                    }
                }
            }
        }
    }
// -----------x-------------x-----------



// Experience Section Style --------------
    .experience{
        .experience-content{
            .experience-bg{
                padding: 20px;
                .experience-body{
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                    
                    .item{
                        padding: {
                            left: 30px;
                            right: 30px;
                        };
                        margin-bottom: 25px;

                        &::before{
                            top: 2px;
                        }
                        .top{
                            font-size: 12px;
                        }
                        .title{
                            font-size: 15px;
                        }
                        .desc{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x-------------



// Skills Section Style -----------------
    .skills-content{
        padding: {
            top: 11%;
            bottom: 6%;
            left: 0%;
        };
    }
// -----------x-------------x-------------




// Portfolio Section Style --------------
    .portfolio{

        .portfolio-content{
            .filter-button{
                display: flex;
                margin-bottom: 35px;
                button{
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
            .grid{
                .item{
                    .item-content{
                        width: 100%;
                        height: 300px;

                        .thumb{
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-details{
        background: $color-content;
        width: 100%;
        height: 100%;

        .area.left{
            margin-bottom: 30px;

            .area-content{
                .item{
                    .item-content{
                        ul.list-line{
                            li{
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
// -----------x-------------x------------



// Blog Section Style -------------------
    .blog{
        width: 100%;
        height: 100%;

        .blog-content{
            text-align: center;

            .item{

                .item-content{

                    .img{
                        height: 320px;
                    }
                    .info{
                        .top{
                            span{
                                font-size: 12px;

                                i,svg{
                                    width: 20px;
                                    height: 20px;
                                    padding: 5px;
                                }
                            }
                        }
                        .title{
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    // blog-details
    .blog-details{
        width: 100%;
        height: 100%;

        .body{
            margin-bottom: 30px;
            .img{
                width: 100%;
                height: 450px;
            }
            .post-comment{
                .comment-user{
                    .item{
                        .item-area{

                            .top{
                                .name{  
                                    font-size: 13px;
                                }
                                .date{
                                    font-size: 12px;
                                }
                            }
                            .body{
                                margin-bottom: 5px;
                                .desc{
                                    font-size: 12px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .item.reply{
                        margin-left: 0px;
                    }
                }
            }
        }
    }
// -----------x-------------x------------



// Contact Section Style -------------------
    .contact{
        .contact-content{
            .item.left{
                margin-bottom: 30px;
            }
        }
    }
// -----------x-------------x------------




}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1300px) {


    .btn-mobile-open{
        display: none;
    }
    .btn-mobile-close{
        display: none;
    }


}

}
